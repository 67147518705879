import isEmpty from 'lodash/isEmpty'
import {useMemo} from 'react'

import OptimizedImage from '../../../ComponentsV2/common/OptimizedImage'

function PlayStoreLink({data = {}, isMobile}) {
  const {
    src,
    width,
    height,
    desktopWidth,
    desktopHeight,
    altText,
    cta = {},
  } = data

  const {link: ctaLink = ''} = cta

  const [widthToRender, heightToRender] = useMemo(() => {
    if (isMobile) {
      return [width, height]
    }
    return [desktopWidth, desktopHeight]
  }, [isMobile, width, height, desktopHeight, desktopWidth])

  if (isEmpty(data)) {
    return null
  }

  return (
    <a
      className="play-store-link"
      href={ctaLink}
      target="_blank"
      rel="noreferrer"
    >
      <OptimizedImage
        source={src}
        aspectWidth={widthToRender}
        aspectHeight={heightToRender}
        desktopViewWidth="FULL"
        mobileViewWidth="FULL"
        alt={altText}
        loading="lazy"
        customClassName="play-store-image"
      />
    </a>
  )
}

export default PlayStoreLink
