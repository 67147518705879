import isEmpty from 'lodash/isEmpty'
import React from 'react'
import DownloadApp from './DownloadApp'

function DownloadAppContainer({state = {}, actions = {}}) {
  if (isEmpty(state)) {
    return null
  }
  return <DownloadApp data={state} />
}

export default DownloadAppContainer
