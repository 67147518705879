import React, {memo} from 'react'
import OptimizedImage from '../../../../common/OptimizedImage'
import {StyledWrapper} from './styles'

const RenderItem = (props) => {
  const {src = '', link = '#', lazyLoad = true, width, height} = props
  return (
    <StyledWrapper>
      <a href={link}>
        <OptimizedImage
          source={src}
          aspectWidth={width}
          aspectHeight={height}
          desktopViewWidth="FULL"
          mobileViewWidth="FULL"
          altText="firstfold"
          loading={lazyLoad ? 'lazy' : 'eager'}
          customClassName="firstfold-img"
        />
      </a>
    </StyledWrapper>
  )
}

export default memo(RenderItem)
