import styled from 'styled-components'

export const StyledDownloadApp = styled.div`
  width: 100%;
  background-color: ${(props) => props.bgColor || '#FOF7FB'};
  padding: 20px 0 0;
  position: relative;
  margin-bottom: 20px;
  .top-info {
    width: 100%;
    display: flex;
    padding-left: 15px;
  }
  .text-info,
  .focus-image-wrapper {
    width: 50%;
    flex-shrink: 0;
  }

  .focus-image-wrapper {
    position: absolute;
    bottom: -15px;
  }
  .offer-text {
    font-size: 13px;
    color: var(--brand-primary-color);
    font-weight: bold;
    margin-bottom: 15px;
  }

  .focus-image {
    width: 100%;
    height: auto;
  }

  .highlights {
    list-style: disc !important;
    padding-left: 4px;
    margin: 4px 4px 10px 10px;

    .highlight {
      font-size: 12px;
      line-height: 18px;
      color: var(--brand-primary-color);
      opacity: 0.8;
    }
  }

  .play-store-links {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    .play-store-link {
      display: flex;
      justify-content: center;
      width: 40%;
      cursor: pointer;

      .play-store-image {
        width: 100%;
        height: auto;
      }
    }
  }

  .image-gallery-bullets.image-gallery-bullets.image-gallery-bullets.image-gallery-bullets {
    position: absolute;
    bottom: 4px;
  }
  .image-gallery-bullets .image-gallery-bullet {
    &.active {
      background-color: var(--brand-primary-color);
    }
  }
  .download-app-carousel {
    padding: 0;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 0 0px;
    margin-bottom: 80px;
    .top-info {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .focus-image-wrap {
      width: 30%;
      margin-left: 100px;
      position: relative;
    }

    .text-info {
      width: 30%;
      text-align: center;
    }

    .highlights {
      margin-left: 0px;
      margin-bottom: 40px;
      list-style: none !important;
      .highlight {
        font-size: 20px;
        font-weight: 400;
        opacity: 0.8;
      }
    }

    .offer-text {
      font-size: 18px;
      font-weight: var(--brand-font-weight-bold);
      margin-bottom: 15px;
      color: var(--brand-primary-color);
    }
    .download-app-carousel {
      padding-bottom: 0;
    }
    .play-store-links {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 50px;

      .play-store-link {
        display: flex;
        justify-content: center;
        width: 40%;

        &:not(:first-child) {
          margin-left: 20px;
        }

        .play-store-image {
          width: 100%;
          height: auto;
        }
      }
    }

    .image-gallery-bullets.image-gallery-bullets.image-gallery-bullets.image-gallery-bullets {
      display: none;
      position: absolute;
      bottom: 4px;
    }
  }
`
export const StyledTitle = styled.div`
  margin: 0;
  font-size: 18px;
  color: var(--brand-primary-color);
  font-weight: var(--brand-font-weight-bold);
  line-height: 27px;

  @media screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 45px;
  }
`
