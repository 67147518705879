import React from 'react'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import FirstFoldCarousel from '../../../ComponentsV2/Screens/CategoryPage/FirstFoldCarousel'
import {StyledDownloadApp, StyledTitle} from './DownloadApp.styles'
import FocusImage from './FocusImage'
import PlayStoreLink from './PlayStoreLink'

function DownloadApp({data = {}, isMobile}) {
  const {
    bgColor,
    title,
    level,
    highlights = [],
    offerText,
    focusImage = {},
    playStoreLinks,
    gallery = {},
  } = data

  return (
    <StyledDownloadApp bgColor={bgColor}>
      <div className="top-info">
        <div className="text-info">
          <StyledTitle as={level}>{title}</StyledTitle>
          <ul className="highlights">
            {highlights.map((highlight, index) => {
              return (
                <li className="highlight" key={index}>
                  {highlight}
                </li>
              )
            })}
          </ul>
          <div className="offer-text">{offerText}</div>
          {!isMobile && (
            <div className="play-store-links">
              {playStoreLinks.map((linkData, index) => {
                return (
                  <React.Fragment key={index}>
                    <PlayStoreLink data={linkData} />
                  </React.Fragment>
                )
              })}
            </div>
          )}
        </div>
        <FocusImage data={focusImage} isMobile={isMobile} />
      </div>
      {isMobile && (
        <div className="play-store-links">
          {playStoreLinks.map((linkData, index) => {
            return (
              <React.Fragment key={index}>
                <PlayStoreLink data={linkData} />
              </React.Fragment>
            )
          })}
        </div>
      )}
      <FirstFoldCarousel
        state={gallery}
        additionalClass="download-app-carousel"
      />
    </StyledDownloadApp>
  )
}

export default Responsive(DownloadApp)
