import isEmpty from 'lodash/isEmpty'
import React, {useMemo} from 'react'
import OptimizedImage from '../../../ComponentsV2/common/OptimizedImage'

function FocusImage({data = {}, isMobile}) {
  const {src, width, height, desktopWidth, desktopHeight, altText} = data

  const [widthToRender, heightToRender] = useMemo(() => {
    if (isMobile) {
      return [width, height]
    }
    return [desktopWidth, desktopHeight]
  }, [isMobile, width, height, desktopHeight, desktopWidth])

  if (isEmpty(data)) {
    return null
  }
  return (
    <div className="focus-image-wrap">
      <OptimizedImage
        source={src}
        aspectWidth={widthToRender}
        aspectHeight={heightToRender}
        desktopViewWidth="HALF"
        mobileViewWidth="HALF"
        altText={altText}
        loading="lazy"
        customClassName="focus-image"
      />
    </div>
  )
}

export default FocusImage
