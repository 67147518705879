import styled from 'styled-components'

export const StyledWrapper = styled.div`
  padding-bottom: 32px;
  @media (min-width: 768px) {
    padding-bottom: 100px;
  }

  &[data-showingdots='true'] {
    padding-bottom: 16px;

    @media (min-width: 768px) {
      padding-bottom: 100px;
    }
  }

  .image-gallery-bullets {
    position: relative;
    bottom: -15px;
    z-index: 0;
    height: 15px;
  }

  .image-gallery-bullets-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-gallery-bullets .image-gallery-bullet {
    border: none;
    background-color: black;
    width: 6px;
    height: 6px;
    box-shadow: none;
    margin-left: 9px;
  }

  .image-gallery-bullets .image-gallery-bullet:first-child {
    margin-left: 0;
  }

  .image-gallery-bullets .image-gallery-bullet.active {
    background-color: var(--brand-primary-color);
    border: none;
    width: 12px;
    height: 12px;
  }

  @media (min-width: 768px) {
    .image-gallery-bullets {
      bottom: -30px;
      height: 24px;
    }

    .image-gallery-bullets .image-gallery-bullet {
      width: 10px;
      height: 10px;
    }
    .image-gallery-bullets .image-gallery-bullet.active {
      width: 20px;
      height: 20px;
    }

    .image-gallery-bullets .image-gallery-bullet {
      margin-left: 15px;
    }
  }
`
